<template>
  <div :class="`${form.formType} Widget`">
    <div class="CampaignWrapper Container">
      <FormPublishState v-if="isUnPublished" :state="form.state" />
      <StepperView
        v-if="!isDonation && !isSale"
        :step="step"
        :navigate="navigate"
        :current-step-component="getCurrentStepComponent()"
        is-widget
      />
      <ActionReport v-if="showActionReport" />
      <DonationView v-if="isDonation" />
      <SaleView v-if="isSale" />
    </div>
    <div class="Widget--Footer">
      <ProvidedByHelloAsso />
    </div>
  </div>
</template>

<script>
import { UrlToApiTypes } from '@/helpers/enums'
import { FormSteps } from '@/helpers/formConfig'
import useFormType from '@/composables/useFormType'
import useStoreData from '@/composables/useStoreData'
import DonationView from '@/components/forms/donations/DonationView.vue'
import SaleView from '@/components/forms/sales/SaleView.vue'
import StepperView from '@/components/forms/stepper/StepperView.vue'
import FormPublishState from '@/components/forms/informations/FormPublishState.vue'
import ProvidedByHelloAsso from '@/components/globals/informations/ProvidedByHelloAsso.vue'
import nodeEnv from '@/helpers/nodeEnv.ts'
import { isUserConnected } from '@/helpers/auth.ts'
import { getMicroData } from '@/helpers/seo'
import ActionReport from '@/components/reports/ActionReport.vue'

const debug = require('debug')('ha-forms:_steps')

export default {
  name: 'Steps',
  components: {
    StepperView,
    FormPublishState,
    ProvidedByHelloAsso,
    DonationView,
    SaleView,
    ActionReport
  },
  layout: 'widget',
  meta: { requiresAuth: false },
  props: {
    errorStatus: {
      type: [String, Number],
      required: false
    }
  },
  setup() {
    const { form } = useStoreData()
    const { isSale, isDonation, isShop, isCrowdfunding, isQuickWinForm } = useFormType()
    return { form, isSale, isDonation, isShop, isCrowdfunding, isQuickWinForm }
  },
  head() {
    return {
      title: this.metaTitle,
      link: this.metaLink,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.description
        },
        { hid: 'robots', name: 'robots', content: 'noindex' },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.metaDescription
        }
      ]
    }
  },
  asyncData({ params }) {
    // set data with route params (before rendering the comp)
    return {
      step: parseInt(params.steps, 10) || 1,
      organizationSlug: params.organizationSlug?.replace(/\s/g, '-'),
      campaignSlug: params.campaignSlug?.replace(/\s/g, '-'),
      type: params.formType
    }
  },
  async fetch({ store, params, error, app }) {
    const orgSlug = params.organizationSlug?.replace(/\s/g, '-')
    // build "metadata" with get params of the route
    const metadata = {
      organization: orgSlug,
      slug: params.campaignSlug?.replace(/\s/g, '-'),
      type: UrlToApiTypes[params.formType]
    }

    try {
      await store.dispatch('forms/fetchFormIfNeeded', metadata)

      // load user data if connected user
      if (isUserConnected(app.$cookies)) {
        await store.dispatch('user/fetchMeIfNeeded')
      }
    } catch (err) {
      if (!nodeEnv.isProduction) debug(err)
      return error(err)
    }
  },
  computed: {
    formSteps() {
      // check for shop if there is no custom field we jump "coordinates" step
      if ((this.isShop || this.isCrowdfunding) && !this.hasCustomFieldsOrOptions) {
        return FormSteps[this.metadata.type].filter((item, index) => index !== 1)
      }
      return FormSteps[this.metadata.type]
    },
    hasCustomFieldsOrOptions() {
      return !!this.form?.tiers?.find(
        item => item.customFields?.length || item.extraOptions?.length
      )
    },
    baseCampaignUrl() {
      return `/associations/${this.organizationSlug}/${this.type}/${this.campaignSlug}/widget`
    },
    isUnPublished() {
      return ['Draft', 'Disabled', 'Deleted'].includes(this.form?.state)
    },
    isFirstStep() {
      return this.step === 1
    },
    showActionReport() {
      return !this.isDonation && !this.isSale && this.isFirstStep && this.isQuickWinForm
    },
    formUrl() {
      return this.form?.url || ''
    },
    isMultilingual() {
      return this.isDonation
    },
    metaLink() {
      return this.isMultilingual
        ? [
            {
              rel: 'canonical',
              href: this.formUrl
            },
            {
              rel: 'alternate',
              hreflang: 'fr',
              href: this.formUrl
            },
            {
              rel: 'alternate',
              hreflang: 'en',
              href: `${this.formUrl}/en`
            }
          ]
        : [
            {
              rel: 'canonical',
              href: this.formUrl
            }
          ]
    }
  },
  jsonld() {
    return getMicroData(this.form, this.metadata.organization)
  },
  created() {
    // Available only for 'formulaires' -> donation
    if (this.$route.name === 'EnglishRouteWidget') {
      this.$i18n.setLocale('en')
    } else {
      this.$i18n.setLocale('fr')
    }
  },
  methods: {
    /**
     * Wraps the $router `push` method to add our URL metadata
     * @param {number} nextStep
     */
    navigate(nextStep) {
      this.step = nextStep
      this.$router.push({
        path: `${this.baseCampaignUrl}/${nextStep}`
      })
    },
    getCurrentStepComponent() {
      if (this.step <= this.formSteps?.length) {
        return this.formSteps[this.step - 1]?.component
      }
      return this.formSteps[0]?.component
    }
  }
}
</script>

<style lang="scss">
.Widget {
  &--Footer {
    margin-top: $ha-spacing-jumbo;
  }

  /**
   * Overrides
   */

  .CampaignWrapper {
    padding: 0;

    > .Columns {
      margin: 0;

      > .Column {
        padding: 0;
      }
    }
  }

  .CampaignForm {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;

    &--Wrapper {
      margin: 0;
    }
  }

  .CampaignCards,
  .CampaignPresentation {
    display: none;
  }

  .NavSteps {
    margin-top: 0;
  }

  .ActionReport--Button {
    margin-top: calc($ha-spacing-big * 2);
  }
}
</style>

<style lang="scss">
/**
 * PaymentForm specific
 */

.PaymentForm {
  background-color: var(--ha-color-background);

  > .Container {
    max-width: 100%;
    padding: 0;
  }
}
</style>
